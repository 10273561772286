export const getCMSPageQuery = /* GraphQL */ `
    query getCMSPage($identifier: String!) {
        cmsPage(identifier: $identifier) {
            title
            identifier
            content
            url_key
            meta_title
            meta_description
            meta_keywords
        }
    }
`
