import { useQuery } from '../use-query'
import type { CmsPage, Maybe } from '@pylot-data/fwrdschema'
import type { SWRConfiguration } from 'swr'
import { RequestInit } from '@vercel/fetch'
import { GraphQLError } from '@pylot-data/graphqlFetch'
import { getCMSPageQuery } from '../../api/operations/graphql/getCMSPageQuery'

export type CmsPageResponse = {
    cmsPage?: Maybe<CmsPage>
    isValidating: boolean
    error?: GraphQLError
}

export const useCmsPage = (
    identifier: string,
    swrOptions?: SWRConfiguration,
    fetchOptions?: RequestInit
): CmsPageResponse => {
    const { data, error, isValidating } = useQuery<
        { identifier: string },
        { cmsPage: CmsPage }
    >(getCMSPageQuery, { identifier }, swrOptions, fetchOptions)

    return {
        cmsPage: data?.data?.cmsPage,
        isValidating,
        error
    }
}
