import { Layout } from '@components/common'
import type {
    GetStaticPropsContext,
    GetStaticPropsResult,
    InferGetStaticPropsType
} from 'next'
import s from '@pagestyles/index.module.scss'
import dynamic from 'next/dynamic'
import getCMSPage from '@pylot-data/api/operations/get-cms'
import React, { ReactElement } from 'react'
import type { CmsPage } from '@pylot-data/pylotschema'
import { serverSideTranslations } from '@pylot-data/serverSideTranslations'
import { useCmsPage } from '@pylot-data/hooks/cms/use-cms-page'
import { PAGE_TYPE, SEO } from '@corratech/pylot-seo'
import { getStoreConfig } from 'config'
import cn from 'classnames'
import { WrappedProductItem } from '@components/common/ProductTile/ProductTile'

const BlogCmsBlockContent = dynamic(() =>
    import('@components/CmsBlockContent').then((mod) => mod.BlogCmsBlockContent)
)
const RichContent = dynamic(() =>
    import('@corratech/pylot-rich-content').then((mod) => mod.RichContent)
)

// Highly unlikely to change. Even if admin user wats to change the content, they can swap page identifiers.
const config = getStoreConfig()
const CMS_PAGE_IDENTIFIER = config?.base?.route?.cms_home_page!

export async function getStaticProps({
    locale
}: GetStaticPropsContext): Promise<
    GetStaticPropsResult<{ cmsPageData: CmsPage | null | undefined }>
> {
    try {
        const cmsPageData = await getCMSPage({
            identifier: CMS_PAGE_IDENTIFIER
        })

        return {
            props: {
                ...(cmsPageData?.content
                    ? { cmsPageData: cmsPageData }
                    : { cmsPageData: null }),
                ...(await serverSideTranslations(locale!, ['common']))
            },
            revalidate: 14400
        }
    } catch (e) {
        console.log(
            'Error in getStaticProps in page with filepath /pages/index.tsx'
        )
        console.log(e)
        return {
            props: {
                cmsPageData: null,
                ...(await serverSideTranslations(locale!, ['common']))
            },
            revalidate: 14400
        }
    }
}

export default function Home({
    cmsPageData
}: InferGetStaticPropsType<typeof getStaticProps>): ReactElement {
    const { cmsPage = cmsPageData } = useCmsPage(CMS_PAGE_IDENTIFIER, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
        fallbackData: cmsPageData
    })

    const {
        base: {
            blog: {
                recent_blog_post_cms = '',
                is_recent_blog_post_cms_enabled
            } = {}
        },
        preload: { home }
    } = config

    return (
        <div className={s.homepage}>
            <SEO
                page={PAGE_TYPE.HOME}
                cmsPage={cmsPage}
                {...(home?.images
                    ? { preloadImages: { images: home.images } }
                    : {})}
            />
            <RichContent
                ProductTile={WrappedProductItem}
                html={cmsPage?.content}
            />
            {is_recent_blog_post_cms_enabled ? (
                <div className="cms-latest-blog-post bg-[#f6f6f6]">
                    <div className="row_contained row_root">
                        <div className={cn(s['am-blog-recent_post'])}>
                            <BlogCmsBlockContent
                                identifier={recent_blog_post_cms}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

Home.Layout = Layout
